<template>
    <v-container class="wrapper_info">
        <v-content>
            <v-card class="wrapper_info_content pa-5">

                <img
                        @click="load_homepage()"
                        data-src="res/wst/wellstar-icon.svg" width="200" height="200" alt="" uk-img class="uk-align-center">
                <h3 class="uk-align-center" style="text-align: center;">Wellstar Trading Ltd</h3>

                <v-divider></v-divider>
                <router-view name="router_wrapper_info">
                </router-view>

            </v-card>
        </v-content>
    </v-container>
</template>

<script>
    export default {
        name: "info_wrapper",
        methods: {
            load_homepage(){
                this.$router.push({path: '/'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper_info{
        padding-top: 0;
        margin-top: 40px;
        padding-bottom: 10px;
    }

    .wrapper_info_content{
        min-height: calc(100vh - 110px);
    }

</style>
